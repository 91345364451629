body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Space Grotesk', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  /* DRY_39032 theme colors */
  --color-gltext: #4b4846;
  --color-charcoal-light: #656565;
  --color-charcoal: #0d0d0d;
  --color-icon-container: #eee;
  --color-fill-superlight: #f5f5f5;
  --color-subtle-bg-grey: #F5F5F5;
  --color-border-light: #e4e4e4;
  --color-opaque-white: #fff;
  --color-focus-blue: #0092ff;
  --color-invalid-red: #a8000a;
  --color-link: #2400ff;
  --color-link-hover: #2400ff;
  --color-link-visited: #7700ff;
  --color-link-active: #ff0000;
  --color-calm-blue: #EAF7FF;
  --color-welcoming-eggshell: #FDF5DF;
  --text-dec-link: none;
  --text-dec-link-hover: underline;
}

h1 {
  font-size: 64px;
  line-height: 1.27;
  font-weight: 400;
  margin: 16px 0;
}
h2 {
  font-size: 48px;
  line-height: 1.27;
  font-weight: 400;
  margin: 16px 0;
}
h3 {
  font-size: 36px;
  line-height: 1.27;
  font-weight: 400;
  margin: 16px 0;
}
h4 {
  font-size: 24px;
  line-height: 1.27;
  font-weight: 400;
  margin: 16px 0;
}
h5 {
  font-size: 16px;
  line-height: 1.27;
  font-weight: 400;
  margin: 16px 0;
}
strong,
b {
  font-weight: 600;
}

a:link {
  color: var(--color-link);
  text-decoration: none;
}
a:hover {
  color: var(--color-link-hover);
  text-decoration: underline;
}
a:visited {
  color: var(--color-link-visited);
  text-decoration: none;
}
a.novisited:visited {
  color: var(--color-link);
  text-decoration: none;
}
a:active {
  color: var(--color-link-active);
  text-decoration: underline;
}

.body-text-xl {
  font-size: 24px;
  line-height: 1.25;
  font-weight: 400;
}
.body-text-l {
  font-size: 20px;
  line-height: 1.3;
  font-weight: 400;
}
.body-text-m {
  font-size: 16px;
  line-height: 1.5;
  font-weight: 400;
}
.body-text-s {
  font-size: 14px;
  line-height: 1.57;
  font-weight: 400;
}

p {
  font-weight: 400;
  margin: 16px 0;
}

.body-text-error {
  color: var(--color-invalid-red);
}
.body-text-light {
  color: var(--color-charcoal-light);
}

button {
  font-family: 'Space Grotesk', sans-serif;
  font-size: 16px;
  line-height: 1.27;
  cursor: pointer;
}

/* containing our form styles inside a .our-forms class wrapper so that forms
 * in the MUI standard firebase ui are not affected */
.our-forms input,
.our-forms textarea {
  /* DRY_71026 textarea metrics */
  font-family: 'Space Grotesk', sans-serif;
  font-size: 16px;
  line-height: 1.27;
  font-weight: 400;
  padding: 3px;
  margin: 0;
  color: var(--color-charcoal);
  border: 1px solid var(--color-charcoal);
  border-radius: 2px;
}
.our-forms input.empty,
.our-forms textarea.empty {
  color: var(--color-charcoal-light);
  border-color: var(--color-charcoal-light);
}
.our-forms input:focus,
.our-forms input:focus-visible,
.our-forms textarea:focus,
.our-forms textarea:focus-visible {
  outline: var(--color-focus-blue) solid 2px;
  border-radius: 3px;
  box-shadow: 0 0 10px #75ACFF;
}
.our-forms input:focus.empty,
.our-forms input:focus-visible.empty,
.our-forms textarea:focus.empty,
.our-forms textarea:focus-visible.empty {
  color: var(--color-charcoal);
  border: 1px solid var(--color-charcoal);
}
.our-forms input.invalid,
.our-forms textarea.invalid {
  border-color: var(--color-invalid-red);
}
.our-forms input:focus.invalid,
.our-forms input:focus-visible.invalid,
.our-forms textarea:focus.invalid,
.our-forms textarea:focus-visible.invalid {
  outline: var(--color-invalid-red) solid 1px;
}

.our-forms textarea{
  resize: none;
}

.nom {
  margin: 0;
}

.ReactCollapse--collapse {
  transition: height 500ms;
}
